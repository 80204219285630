import React from 'react';
import BackButton from '@components/atoms/BackButton';
import ColonFix from '@components/atoms/ColonFix';
import Container from '@components/atoms/Container';
import Image from '@components/atoms/Image';
import SocialSharingBlock from '@components/molecules/SocialSharingBlock';
import FlexibleFields from '@components/organisms/FlexibleFields';
import Footer from '@components/organisms/footer';
import Layout from '@components/organisms/Layout';
import Navigation from '@components/organisms/navigation';
import SEO from '@containers/SEO';

import './style.case-study.scss';

const CaseStudyTemplate = ({pageContext: {page, breadcrumb}, location}) => {
    const {seoMetadata, featuredImage} = page;

    return (
        <Layout page={page}>
            <SEO
                pageMeta={seoMetadata}
                pageTitle={page.title}
                breadcrumb={breadcrumb}
            />
            <Navigation />
            <Container>
                <header>
                    <BackButton />
                    <div className="hudl-margin-y--lg">
                        {page.pretitle && (
                            <h3 className="hudl-delta-type hudl-pretitle">
                                {page.pretitle}
                            </h3>
                        )}
                        <h1 className="hudl-alpha-type">
                            <ColonFix string={page.title} />
                        </h1>
                    </div>
                    <SocialSharingBlock
                        className="hudl-margin-bottom--xl"
                        location={location}
                    />
                </header>
            </Container>
            <div className="hudl-case-study__featured-image">
                <Image
                    imgsrc={featuredImage.image}
                    title={featuredImage.imageTitle}
                    alt={featuredImage.imageAlt}
                />
            </div>
            <FlexibleFields fields={page.flexibleFields} />
            <Footer />
        </Layout>
    );
};

export default CaseStudyTemplate;
