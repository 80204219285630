import React from 'react';
import {useLanguageContext} from '@helpers/LanguageContext';
import dictionary from '@netlify/site-settings/dictionary.yml';

const BackButton = () => {
    const {pageLanguageName} = useLanguageContext();

    const back = () => {
        if (window.previousOrigin === window.location.origin) {
            // If we navigated from somewhere else on the website, go to that page.
            window.history.back();
        } else {
            // If we navigated from an external page, go to the homepage.
            window.location.href = window.location.origin;
        }
    };

    return (
        <button
            className="hudl-button hudl-button--tertiary-button hudl-margin-top--md hudl-case-study-back-button"
            onClick={back}
            type="button"
        >
            {dictionary[pageLanguageName].back}
        </button>
    );
};

export default BackButton;
